<template>
  <div>
    <div style=" height: 90vh"  class=" w-100 position-absolute d-flex align-items-center justify-content-center " 
    v-if=" $store.state.loading || loading   " >
      <span class="spinner" ></span>
      
    </div>
  
  <section v-else class="main main_padding-top bg-white " style="min-height: 100vh;">
  

    <div class=" py-sm-4 py-4" id="order">
        <div class="rc-root">
            <div class="Page">
                <div class="Page__body Page__width">
                    <div class=" row NewOrder">
                      <div class=" custom__body col-sm-8 col-12 p-5 NewOrder__main">
                          <div class="NewOrder__header ">
                              <div class="NewOrder__header__left text-left">
                                  <div class="NewOrder__heading">Place an order</div>
                                  <div class="NewOrder__subheading">
                                    <span>It’s fast, secure, and confidential</span>
                                  </div>
                              </div>
                              <div class="NewOrder__header__right text-sm right text-left py-sm-0 py-3 ">
                                <router-link :to="{ name: 'Dashboard' }"   class="UIButton_default_ghost " type="submit">Dashboard </router-link>
                                   
                              </div>
                                
                        </div>
                        <div class="NewOrder__body">
                            <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                <div class="rc-orderform">
                                    <div 
                                    
                                    class="rc-orderform__step  "
                                    
                                    >
                                          <div class="rc-orderform__step-flag ">
                                            <span class="rc-orderform__step-flag__count ">1.</span>Paper details
                                          </div> 
                                          
                                            <div class="rc-orderform__step__body">

                                            <div class="rc-orderform__row  ">
                                            <div class="rc-orderform__feature-heading  text-sm-right text-left   "></div>
                                            <div class="rc-orderform__feature-body  ">
                                                <div class="rc-validation-representer valid" data-invalid="false">

                                                  <div  class=" scroll-container " ref="scrollContainer">
                                                    <div  class="rc-radios rc-radios--theme-default vertical-on-small ServiceTypeControl  "  data-testid="serviceTypeControl">
                                                      <div v-for="formType in orderFormTypes" :key="formType" class="radio-button-wrapper radio-button-wrapper--flexbuttons radio-button-wrapper--same-width">
                                                        <button @click="changeOrderTypeForm(formType)"  type="button" class="radio-button radio-button--n-37 " :class="orderForm_type == formType.desc ? 'active' : '' "  tabindex="-1" data-testid="radio-button">
                                                          <div class="button-checkbox">
                                                            
                                                          </div>
                                                          <div class="ServiceTypeControl-button ServiceTypeControl-button--academic-writing">
                                                            <div class="ServiceTypeControl-button__title">{{formType.title}}</div>
                                                            <div class="ServiceTypeControl-button__subTitle">{{formType.desc}}</div>
                                                          </div>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  
                                                  </div>
                                                  <div v-if="orderForm_type == 'Shadow Health' " class=" scroll-container mt-2 " ref="scrollContainer">
                                                    <div  class="rc-radios rc-radios--theme-default vertical-on-small ServiceTypeControl  "  data-testid="serviceTypeControl">
                                                      
                                                        <div
                                                          class="
                                                            btn-group
                                                            atbd-button-group
                                                            btn-group-normal
                                                            my-2
                                                            overflow-auto
                                                          "
                                                          role="group"
                                                        >
                                                        <!-- {{ orderForm.academic_level }} -->
                                                          <button
                                                            style="min-width: 240px !important; line-height:1.3em!important; "
                                                            v-for="(shadow, index) in ShadowHealthTypes"
                                                            :key="index + 'om'"
                                                            type="button"
                                                            class="col-3 py-2 px-1 d-flex align-items-center justify-content-center btn-sm btn-outline-light radio-button radio-button--n-1"
                                                            :class="
                                                              orderForm.type == shadow.type
                                                                ? 'active'
                                                                : ''
                                                            "
                                                            @click="activateShadowHealth(shadow)"
                                                          >
                                                            {{ shadow.desc }}
                                                          </button>
                                                        </div>
                                                      <!-- </div> -->
                                                    </div>
                                                  </div>
                                                <!-- <div  style=" overflow: auto !important;" class="rc-radios rc-radios--theme-default vertical-on-small">
                                                    <div  v-for="(level, index) in academic_levels"
                                                      :key="index + 'om'" class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                        <button 
                                                        :class="
                                                          orderForm.academic_level === level.id
                                                            ? 'active'
                                                            : ''
                                                        "
                                                        style=" min-width:120px!important;  "
                                                        @click="activateAcademicLevel(level)"
                                                        type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                            <div class="button-checkbox"></div>
                                                            <div class="radio-button__content">{{ level.desc }}</div>
                                                        </button>
                                                    </div>
                                                    
                                                  </div> -->
                                                </div>
                                            </div>
                                            </div>

                                            <form v-if="orderForm_type == 'Custom Papers'" @submit.prevent="doPlaceOrder">
                                              <div class="rc-orderform__row  ">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left   ">Academic level</div>
                                              <div class="rc-orderform__feature-body  ">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                  <div  style=" overflow: auto !important;" class="rc-radios rc-radios--theme-default vertical-on-small">
                                                      <div  v-for="(level, index) in academic_levels"
                                                        :key="index + 'om'" class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                          <button 
                                                          :class="
                                                            orderForm.academic_level === level.id
                                                              ? 'active'
                                                              : ''
                                                          "
                                                          style=" min-width:120px!important;  "
                                                          @click="activateAcademicLevel(level)"
                                                          type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                              <div class="button-checkbox"></div>
                                                              <div class="radio-button__content">{{ level.desc }}</div>
                                                          </button>
                                                      </div>
                                                      
                                                    </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--type-of-paper">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left  text-sm-right text-left">Type of paper</div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-validation-representer" data-invalid="false">
                                                          <div class="Select TypeOfPaperControl__select Select--single is-searchable">
                                                            <!-- <select
                                                              @change="calculatePrice"
                                                              v-model="orderForm.document_type"
                                                              name="select-size-9"
                                                              id="select-size-9"
                                                              class="border_gray_200 Select-multi-value-wrapper form-control form-control-md"
                                                            >
                                                              <option
                                                                v-for="(item, index) in document_types"
                                                                :key="index + 'om'"
                                                                :value="item.id"
                                                              >
                                                                {{ item.desc }}
                                                              </option>
                                                            </select> -->
                                                            <vue-select
                                                              class="Select-multi-value-wrapper form-control-md"
                                                              label="desc"
                                                              v-model="orderForm.document_type"
                                                              placeholder="Start typing to search"
                                                              @input="calculatePrice"
                                                              :options="document_types"
                                                              :reduce="(item) => item.id"
                                                            ></vue-select>
                                                              
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--discipline">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left  text-sm-right text-left">Discipline</div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div>
                                                          <div class="rc-validation-representer" data-invalid="false">
                                                              <div class="Select DisciplineControl__select Select--single is-searchable">
                                                                <!-- <select
                                                                  @change="calculatePrice"
                                                                  v-model="orderForm.subject_area"
                                                                  name="select-size-9"
                                                                  id="select-size-9"
                                                                  class="border_gray_200 form-control form-control-md"
                                                                >
                                                                  <option
                                                                    v-for="(item, index) in subject_areas"
                                                                    :key="index + 'om'"
                                                                    :value="item.id"
                                                                  >
                                                                    {{ item.desc }}
                                                                  </option>
                                                                </select> -->

                                                                <vue-select
                                                                  class="Select-multi-value-wrapper form-control-md"
                                                                  label="desc"
                                                                  v-model="orderForm.subject_area"
                                                                  placeholder="Start typing to search"
                                                                  @change="calculatePrice"
                                                                  :options="subject_areas"
                                                                  :reduce="(item) => item.id"
                                                                ></vue-select>
                                                              
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Topic <span class="text-danger" >*</span> </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input :class="!!errors.title ? 'invalid' : ''" v-model="orderForm.title" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter the title of your paper" required tabindex="0" >
                                                          <p
                                                            class="text-danger text-left my-1"
                                                            v-if="'title' in errors"
                                                          >
                                                            {{ errors.title }}
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Paper instructions<span class="text-danger" >*</span></div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <vue-editor
                                                            :class="!!errors.description ? 'invalid' : ''"
                                                            v-model="orderForm.description"
                                                          ></vue-editor>
                                                          <!-- <textarea v-model="orderForm.description" class="PaperInstructionsControl_textarea UIInput border_custom--input UIInput-default UIInput-default--type-textarea UIInput-default--size-m UIInput-default--color-default UIInput-default--autosize UIInput-default--not-resizable" placeholder="Write anything you feel is important for the writer to consider. An outline, a grading scale, and other documents may be uploaded as additional materials." tabindex="0" style="overflow: hidden; overflow-wrap: break-word; min-height: 150px;"></textarea> -->
                                                      </div>
                                                      <p
                                                        class="text-danger text-left my-1"
                                                        v-if="'title' in errors"
                                                      >
                                                        {{ errors.title }}
                                                      </p>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--add-materials">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Additional materials</div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                        <FileUploader v-model="orderForm.files"></FileUploader>

                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                      <div class="rc-orderform__feature-heading  text-sm-right text-left">Paper format</div>
                                                      <div class="rc-orderform__feature-body"><div>
                                                          <div class="rc-validation-representer valid" data-invalid="false">
                                                              <div style=" overflow: auto !important;  " class="rc-radios rc-radios--theme-default vertical-on-small">
                                                                  <div
                                                                  v-for="(item, index) in styles"
                                                                  :key="index + 'om'" 
                                                                  class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                                      <button
                                                                      style=" min-width:70px!important;  "
                                                                      :class="
                                                                        orderForm.style === item.id
                                                                          ? 'active'
                                                                          : ''" 
                                                                          @click="activateAcademicStyle(item)"
                                                                      type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                                          <div class="button-checkbox"></div><div class="radio-button__content"> {{ item.desc }}</div>
                                                                      </button>
                                                                  </div>
                                                                  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                      <div class="rc-orderform__feature-heading  text-sm-right text-left">Language Style</div>
                                                      <div class="rc-orderform__feature-body  "><div>
                                                          <div class="rc-validation-representer valid" data-invalid="false">
                                                              <div  style=" overflow: auto !important;"  class="rc-radios rc-radios--theme-default vertical-on-small">
                                                                  <div
                                                                  v-for="(style, index) in language_styles"
                                                                  :key="index + 'om'" 
                                                                  class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                                      <button
                                                                      style=" min-width:120px!important;  "
                                                                      :class="
                                                                        orderForm.language_style === style.id
                                                                          ? 'active'
                                                                          : ''" 
                                                                          @click="activateLanguageStyle(style)"
                                                                      type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                                          <div class="button-checkbox"></div><div class="radio-button__content"> {{ style.desc }}</div>
                                                                      </button>
                                                                  </div>
                                                                  
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--deadline">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left">Deadline</div>
                                              <div class="rc-orderform__feature-body">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                      <div style=" overflow: auto !important; " class="rc-radios rc-radios--theme-default vertical-on-small">
                                                          <div 
                                                          v-for="(item, index) in urgency"
                                                              :key="index + 'om'"
                                                          
                                                          class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                              <button
                                                              
                                                              :class="
                                                                orderForm.urgency === item.id
                                                                  ? 'active'
                                                                  : ''" 
                                                                  @click="activateUrgency(item)" 
                                                                  style="min-width: 90px !important;  " 
                                                              type="button" class="radio-button radio-button--n-10" tabindex="0">
                                                                  <div class="button-checkbox"></div>
                                                                  <div class="DeadlineRadioControl__radiobutton-content">
                                                                      <div class="DeadlineRadioControl__radiobutton-content__hours py-2 ">{{item.desc}}</div>
                                                                  </div>
                                                              </button>
                                                          </div>
                                                          
                                                              
                                                          </div>
                                                      </div>
                                                      <!-- <div class="DeadlineDate DeadlineControl__estimated-deadline"><span>We'll send you the order for review by <span class="DeadlineDate__date">Oct 17, 2022 at 10:11 AM</span></span>. <button type="button" class="DeadlineDate__calendar-button">Show calendar</button></div> -->
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__pages-spacing">
                                                      <div class="rc-orderform__pages-spacing__pages">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Pages</div>
                                                          <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer valid" data-invalid="false">
                                                                  <div class="rc-stepper">
                                                                      <button @click="updatePages(0)" class="rc-stepper__minus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-right" type="button">– </button>
                                                                      <input 
                                                                      @change="calculatePrice"
                                                                      v-model="orderForm.pages"
                                                                      name="pages"
                                                                      min="0"
                                                                      :max="number_of_pages.max_pages"
                                                                      type="number" class="rc-stepper__input UIInput UIInput-default UIInput-default--type-number UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                                      <button @click="updatePages(1)" class="rc-stepper__plus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-left" type="button">+ </button>
                                                                  </div>
                                                              </div>
                                                              <div class="rc-orderform__pages-spacing__words">Aprox {{ no_of_words || "" }} words</div>
                                                          </div>
                                                      </div>
                                                      <div class="rc-orderform__pages-spacing__spacing">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Spacing</div>
                                                          <div class="rc-orderform__feature-body">
                                                              <div class="rc-validation-representer valid" data-invalid="false">
                                                                  <div class="rc-radios rc-radios--theme-default SpacingControl">
                                                                      <div 
                                                                      v-for="(space, index) in spacing"
                                                                          :key="index + 'om'"
                                                                          class="radio-button-wrapper ">
                                                                          <button
                                                                          
                                                                          :class="
                                                                            orderForm.spacing == space.id ? 'active' : ''
                                                                          "
                                                                          @click="activateSpacing(space)"
                                                                          type="button" 
                                                                          class="radio-button radio-button--n-single" tabindex="0">
                                                                              <div class="button-checkbox"></div>
                                                                              <div class="radio-button__content">{{space.desc}}</div>
                                                                          </button>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__sources-charts-slides">
                                              <div class="rc-orderform__row rc-orderform__row--slides">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left">Sources to be cited</div>
                                              <div class="rc-orderform__feature-body text-left">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                      <div class="rc-stepper">
                                                          <button  @click="decrementSources(0)" class="rc-stepper__minus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-right" type="button">– </button>
                                                          <input 
                                                          v-model="orderForm.no_sources"
                                                          name="no_sources"
                                                          min="0" 
                                                          max="999" type="number" class="rc-stepper__input UIInput UIInput-default UIInput-default--type-number UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                          <button @click="orderForm.no_sources++" class="rc-stepper__plus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-left" type="button">+ </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--slides">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left">Charts</div>
                                              <div class="rc-orderform__feature-body text-left">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                      <div class="rc-stepper">
                                                          <button @click="updateCharts(0)" class="rc-stepper__minus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-right" type="button">– </button>
                                                          <input @change="calculatePrice" v-model="orderForm.charts" min="0" max="999" type="number" class="rc-stepper__input UIInput UIInput-default UIInput-default--type-number UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                          <button @click="updateCharts(1)" class="rc-stepper__plus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-left" type="button">+ </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--charts">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">PowerPoint slides</div>
                                                  <div class="rc-orderform__feature-body text-left">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <div class="rc-stepper">
                                                              <button @click="updateSlides(0)" class="rc-stepper__minus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-right" type="button">– </button>
                                                              <input min="0" v-model="orderForm.slides" max="999" type="number" class="rc-stepper__input UIInput UIInput-default UIInput-default--type-number UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" @change="calculatePrice">
                                                              <button @click="updateSlides(1)" class="rc-stepper__plus-button UIButton UIButton-default-ghost UIButton-default-ghost--size-m UIButton-default-ghost--color-primary UIButton-default-ghost--attach-left" type="button">+ </button>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--writer-category">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Writer category</div>
                                                  <div class="rc-orderform__feature-body  ">
                                                      <div class="rc-validation-representer CategoryOfWriterControl valid" data-invalid="false">
                                                          <div
                                                          style="overflow:auto!important;"
                                                          class="rc-radios rc-radios--theme-default vertical-on-small">
                                                              
                                                              <div
                                                              style="min-width: 180px!important;"
                                                                v-for="(pref, index) in writer_levels"
                                                                :key="index + 'om'"
                                                              class="radio-button-wrapper radio-button-wrapper">
                                                                  <button 
                                                                  :class="
                                                                    orderForm.writer_level.includes(pref.id)
                                                                      ? 'active'
                                                                      : ''
                                                                  "
                                                                  @click="activateWriterPref(pref)"
                                                                  type="button" class="radio-button radio-button--n-3 " tabindex="-1">
                                                                      <div class="button-checkbox"></div>
                                                                      <div class="CategoryOfWriterControl__item">
                                                                          <div class="CategoryOfWriterControl__item__price">{{pref.cost}}</div>
                                                                          <span class="CategoryOfWriterControl__item__title">{{ pref.title }}</span>
                                                                          <div class="CategoryOfWriterControl__item__body">{{pref.desc}}</div>
                                                                      </div>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                            </form>
                                            <form v-if="orderForm_type == 'Custom Exams'"  action="">
                                              <div class="rc-orderform__row rc-orderform__row--type-of-paper">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left  text-sm-right text-left">Type of Exams</div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-validation-representer" data-invalid="false">
                                                          <div class="Select TypeOfPaperControl__select Select--single is-searchable">
                                                            <select
                                                              @change="calculatePrice"
                                                              v-model="orderForm.description_extras.type"
                                                              name="select-size-9"
                                                              id="select-size-9"
                                                              class="border_gray_200 Select-multi-value-wrapper form-control form-control-md"
                                                            >
                                                              <option
                                                                value="Midterm exam"
                                                              >
                                                              Midterm exam
                                                              </option>
                                                              <option
                                                                value="Final exam"
                                                              >
                                                              Final exam
                                                              </option>
                                                            </select>
                                                              
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row  ">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left   ">Academic level</div>
                                              <div class="rc-orderform__feature-body  ">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                  <div  style=" overflow: auto !important;" class="rc-radios rc-radios--theme-default vertical-on-small">
                                                      <div  v-for="(level, index) in academic_levels"
                                                        :key="index + 'om'" class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                          <button 
                                                          @change="calculatePrice"
                                                          :class="
                                                            orderForm.academic_level === level.id
                                                              ? 'active'
                                                              : ''
                                                          "
                                                          style=" min-width:120px!important;  "
                                                          @click="activateAcademicLevel(level)"
                                                          type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                              <div class="button-checkbox"></div>
                                                              <div class="radio-button__content">{{ level.desc }}</div>
                                                          </button>
                                                      </div>
                                                      
                                                    </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Course code <span class="text-danger" >*</span> </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.course_code" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Course code" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Course name <span class="text-danger" >*</span> </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.course_name" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Course name" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">University </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.university" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter University" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--type-of-paper">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left  text-sm-right text-left">Delivery Time </div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-validation-representer" data-invalid="false">
                                                          <div class="Select TypeOfPaperControl__select Select--single is-searchable">
                                                            <select
                                                              @change="calculatePrice"
                                                              v-model="orderForm.special_urgency"
                                                              name="select-size-9"
                                                              id="select-size-9"
                                                              class="border_gray_200 Select-multi-value-wrapper form-control form-control-md"
                                                            >
                                                            <option
                                                              value="3"
                                                            >
                                                              3 Days
                                                            </option>
                                                            <option
                                                              value="5"
                                                            >
                                                              5 Days
                                                            </option>
                                                            <option
                                                              value="10"
                                                            >
                                                              10 Days
                                                            </option>
                                                            </select>
                                                              
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--add-materials">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Additional materials</div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                        <FileUploader v-model="orderForm.files"></FileUploader>

                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Email/username </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.school_email_username" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Email/username" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Password </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.school_password" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Password" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Comments</div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <textarea v-model="orderForm.description_extras.comments" class="PaperInstructionsControl_textarea UIInput border_custom--input UIInput-default UIInput-default--type-textarea UIInput-default--size-m UIInput-default--color-default UIInput-default--autosize UIInput-default--not-resizable" placeholder="Please share any additional comments here..." tabindex="0" style="overflow: hidden; overflow-wrap: break-word; min-height: 150px;"></textarea>
                                                      </div>
                                                  </div>
                                              </div>

                                            </form>
                                            <form v-if="orderForm_type == 'Custom Quiz'"  action="">
                                              
                                              
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Course code <span class="text-danger" >*</span> </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.course_code" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Course code" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Course name <span class="text-danger" >*</span> </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.course_name" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Course name" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">University </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.university" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter University" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row  ">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left   ">Academic level</div>
                                              <div class="rc-orderform__feature-body  ">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                  <div  style=" overflow: auto !important;" class="rc-radios rc-radios--theme-default vertical-on-small">
                                                      <div  v-for="(level, index) in academic_levels"
                                                        :key="index + 'om'" class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                          <button 
                                                          @change="calculatePrice"
                                                          :class="
                                                            orderForm.academic_level === level.id
                                                              ? 'active'
                                                              : ''
                                                          "
                                                          style=" min-width:120px!important;  "
                                                          @click="activateAcademicLevel(level)"
                                                          type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                              <div class="button-checkbox"></div>
                                                              <div class="radio-button__content">{{ level.desc }}</div>
                                                          </button>
                                                      </div>
                                                      
                                                    </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--type-of-paper">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left  text-sm-right text-left">Delivery Time </div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-validation-representer" data-invalid="false">
                                                          <div class="Select TypeOfPaperControl__select Select--single is-searchable">
                                                            <select
                                                              @change="calculatePrice"
                                                              v-model="orderForm.special_urgency"
                                                              name="select-size-9"
                                                              id="select-size-9"
                                                              class="border_gray_200 Select-multi-value-wrapper form-control form-control-md"
                                                            >
                                                            <option
                                                              value="3"
                                                            >
                                                              3 Days
                                                            </option>
                                                            <option
                                                              value="5"
                                                            >
                                                              5 Days
                                                            </option>
                                                            <option
                                                              value="10"
                                                            >
                                                              10 Days
                                                            </option>
                                                            </select>
                                                              
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--add-materials">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Additional materials</div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                        <FileUploader v-model="orderForm.files"></FileUploader>

                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Email/username </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.school_email_username" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Email/username" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Password </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.school_password" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Password" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Comments</div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <textarea v-model="orderForm.description_extras.comments" class="PaperInstructionsControl_textarea UIInput border_custom--input UIInput-default UIInput-default--type-textarea UIInput-default--size-m UIInput-default--color-default UIInput-default--autosize UIInput-default--not-resizable" placeholder="Please share any additional comments here..." tabindex="0" style="overflow: hidden; overflow-wrap: break-word; min-height: 150px;"></textarea>
                                                      </div>
                                                  </div>
                                              </div>

                                            </form>
                                            <form v-if="orderForm_type == 'Custom iHuman'"  action="">
                                              
                                              
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Case Study name  </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.title" :class="!!errors.title ? 'border_danger_200' : ''" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Give name of the case study ( eg Tina Jones Health History)" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--type-of-paper">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left  text-sm-right text-left">Delivery Time </div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-validation-representer" data-invalid="false">
                                                          <div class="Select TypeOfPaperControl__select Select--single is-searchable">
                                                            <select
                                                              @change="calculatePrice"
                                                              v-model="orderForm.special_urgency"
                                                              name="select-size-9"
                                                              id="select-size-9"
                                                              class="border_gray_200 Select-multi-value-wrapper form-control form-control-md"
                                                            >
                                                            <option
                                                              value="3"
                                                            >
                                                              3 Days
                                                            </option>
                                                            <option
                                                              value="5"
                                                            >
                                                              5 Days
                                                            </option>
                                                            <option
                                                              value="10"
                                                            >
                                                              10 Days
                                                            </option>
                                                            </select>
                                                              
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row  ">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left   ">Academic level</div>
                                              <div class="rc-orderform__feature-body  ">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                  <div  style=" overflow: auto !important;" class="rc-radios rc-radios--theme-default vertical-on-small">
                                                      <div  v-for="(level, index) in academic_levels"
                                                        :key="index + 'om'" class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                          <button 
                                                          @change="calculatePrice"
                                                          :class="
                                                            orderForm.academic_level === level.id
                                                              ? 'active'
                                                              : ''
                                                          "
                                                          style=" min-width:120px!important;  "
                                                          @click="activateAcademicLevel(level)"
                                                          type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                              <div class="button-checkbox"></div>
                                                              <div class="radio-button__content">{{ level.desc }}</div>
                                                          </button>
                                                      </div>
                                                      
                                                    </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--add-materials">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Upload Documents</div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                        <FileUploader v-model="orderForm.files"></FileUploader>

                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Comments</div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <textarea v-model="orderForm.description_extras.comments" class="PaperInstructionsControl_textarea UIInput border_custom--input UIInput-default UIInput-default--type-textarea UIInput-default--size-m UIInput-default--color-default UIInput-default--autosize UIInput-default--not-resizable" placeholder="Please share any additional comments here..." tabindex="0" style="overflow: hidden; overflow-wrap: break-word; min-height: 150px;"></textarea>
                                                      </div>
                                                  </div>
                                              </div>

                                            </form>
                                            <form v-if="orderForm_type == 'Shadow Health'"  action="">
                                              
                                              
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Case Study name  </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.title" :class="!!errors.title ? 'border_danger_200' : ''" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Give name of the case study ( eg Tina Jones Health History)" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row rc-orderform__row--type-of-paper">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left  text-sm-right text-left">Delivery Time </div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-validation-representer" data-invalid="false">
                                                          <div class="Select TypeOfPaperControl__select Select--single is-searchable">
                                                            <select
                                                              @change="calculatePrice"
                                                              v-model="orderForm.special_urgency"
                                                              name="select-size-9"
                                                              id="select-size-9"
                                                              class="border_gray_200 Select-multi-value-wrapper form-control form-control-md"
                                                            >
                                                            <option
                                                              value="3"
                                                            >
                                                              3 Days
                                                            </option>
                                                            <option
                                                              value="5"
                                                            >
                                                              5 Days
                                                            </option>
                                                            <option
                                                              value="10"
                                                            >
                                                              10 Days
                                                            </option>
                                                            </select>
                                                              
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div v-if="orderForm.type != 'shadow' " class="rc-orderform__row rc-orderform__row--add-materials">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Upload Template</div>
                                                  <div class="rc-orderform__feature-body"><div>
                                                      <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                        <FileUploader v-model="orderForm.files"></FileUploader>

                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row  ">
                                              <div class="rc-orderform__feature-heading  text-sm-right text-left   ">Academic level</div>
                                              <div class="rc-orderform__feature-body  ">
                                                  <div class="rc-validation-representer valid" data-invalid="false">
                                                  <div  style=" overflow: auto !important;" class="rc-radios rc-radios--theme-default vertical-on-small">
                                                      <div  v-for="(level, index) in academic_levels"
                                                        :key="index + 'om'" class="radio-button-wrapper radio-button-wrapper radio-button-wrapper--flexbuttons">
                                                          <button 
                                                          @change="calculatePrice"
                                                          :class="
                                                            orderForm.academic_level === level.id
                                                              ? 'active'
                                                              : ''
                                                          "
                                                          style=" min-width:120px!important;  "
                                                          @click="activateAcademicLevel(level)"
                                                          type="button" class="radio-button radio-button--n-1 " tabindex="-1">
                                                              <div class="button-checkbox"></div>
                                                              <div class="radio-button__content">{{ level.desc }}</div>
                                                          </button>
                                                      </div>
                                                      
                                                    </div>
                                                  </div>
                                              </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Email/username </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.school_email_username" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Email/username" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Password </div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <input v-model="orderForm.description_extras.school_password" maxlength="100" type="text" class="UIInput border_custom--input UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" placeholder="Enter Password" required tabindex="0" >
                                                          
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="rc-orderform__row">
                                                  <div class="rc-orderform__feature-heading  text-sm-right text-left">Comments</div>
                                                  <div class="rc-orderform__feature-body">
                                                      <div class="rc-validation-representer valid" data-invalid="false">
                                                          <textarea v-model="orderForm.description_extras.comments" class="PaperInstructionsControl_textarea UIInput border_custom--input UIInput-default UIInput-default--type-textarea UIInput-default--size-m UIInput-default--color-default UIInput-default--autosize UIInput-default--not-resizable" placeholder="Please share any additional comments here..." tabindex="0" style="overflow: hidden; overflow-wrap: break-word; min-height: 150px;"></textarea>
                                                      </div>
                                                  </div>
                                              </div>

                                            </form>

                                            </div>
                                        </div>
                                        <!-- {{authUser}} -->
                                        <div class="rc-orderform__step">
                                          <div 
                                          
                                          class="rc-orderform__step-flag">
                                            <span class="rc-orderform__step-flag__count">2.</span>Account
                                          </div>
                                          
                                            <div class="rc-orderform__step__body">
                                                <div v-if="authUser && Object.keys(authUser).length > 0" class="rc-message success plate">Your email is <strong>{{ authUser.email }}</strong>
                                                </div>
                                                <div v-else class="Auth row " style="min-height: 0px;">
                                                  <div class="Auth__tabs text-left col-12">
                                                    <button  @click="customer_type = 'returning'"  type="button" class="Auth__tabs__item " 
                                                    :class="customer_type == 'returning' ? 'Auth__tabs__item--active' : '' ">Returning customer</button>
                                                    <button @click="customer_type = 'new'" type="button" class="Auth__tabs__item"
                                                    :class="customer_type == 'new' ? 'Auth__tabs__item--active' : '' "
                                                    >New customer</button>
                                                  </div>
                                                  <div class="Auth__body col-12">
                                                    <form  v-if="customer_type == 'returning'" @submit.prevent="doPlaceOrder('login')">
                                                      <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                        <div class="rc-orderform__row">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Email or ID</div>
                                                          <div class="rc-orderform__feature-body">
                                                            <div class="rc-validation-representer" data-invalid="false">
                                                              <input v-model="loginForm.email" name="email" autocomplete="email" type="text" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <!-- {{show_password}} -->
                                                        <div class="rc-orderform__row">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Password</div>
                                                          <div class="rc-orderform__feature-body">
                                                            <div class="rc-validation-representer" data-invalid="false">
                                                              <div class="UIPasswordInput">
                                                                <input v-model="loginForm.password" name="password" autocomplete="current-password" type="password" class="UIInput UIInput-default border_custom--input UIInput-default--type-password UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                                <button @click="togglePassword('password')" type="button" class="UIPasswordInput__button" title="Show password"></button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="Auth__bottom-buttons Auth__bottom-buttons--sign-in">
                                                          <div class="AuthSignIn__links">
                                                            <router-link  :to="{name: 'ForgotPassword'  } " class="Auth__link UIButton UIButton-default-clean UIButton-default-clean--size-m UIButton-default-clean--color-primary" type="button">Forgot password </router-link>
                                                          </div>
                                                          <button  class="UIButton UIButton-default-ghost UIButton-default-ghost--size-l UIButton-default-ghost--color-primary UIButton-default-ghost--accent" type="submit">Sign in </button>
                                                        </div>
                                                      </div>
                                                    </form>

                                                    <form  v-if="customer_type == 'new'" @submit.prevent="doPlaceOrder('register')">
                                                      <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                                        <div class="rc-orderform__row">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">First Name</div>
                                                          <div class="rc-orderform__feature-body">
                                                            <div class="rc-validation-representer" data-invalid="false">
                                                              <input v-model="registerForm.first_name" name="email" autocomplete="text" type="text" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="rc-orderform__row">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Last Name</div>
                                                          <div class="rc-orderform__feature-body">
                                                            <div class="rc-validation-representer" data-invalid="false">
                                                              <input v-model="registerForm.last_name" name="email" autocomplete="text" type="text" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="rc-orderform__row">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Email</div>
                                                          <div class="rc-orderform__feature-body">
                                                            <div class="rc-validation-representer" data-invalid="false">
                                                              <input name="email" v-model="registerForm.email" autocomplete="email" type="email" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="rc-orderform__row">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Phone Number</div>
                                                          <div class="rc-orderform__feature-body">
                                                            <div class="rc-validation-representer" data-invalid="false">
                                                              <input v-model="registerForm.phone" name="email" autocomplete="phone number" type="text" class="UIInput UIInput-default UIInput-default--type-text border_custom--input UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <!-- {{registerForm.country}} -->
                                                        <div class="rc-orderform__row">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Country</div>
                                                          <div class="rc-orderform__feature-body">
                                                            <div class="rc-validation-representer" data-invalid="false">
                                                              <vue-select
                                                                class="Select-multi-value-wrapper form-control-md"
                                                                label="name"
                                                                v-model="registerForm.country"
                                                                placeholder="Start typing to search"
                                                                @input="calculatePrice"
                                                                :options="countryNames"
                                                                :reduce="(item) => item.name"
                                                              ></vue-select>
                                                              <!-- <select
                                                                v-model="registerForm.country"
                                                                name="select-size-9"
                                                                id="select-size-9"
                                                                class="border_gray_200 form-control form-control-md"
                                                              >
                                                                <option
                                                                  v-for="(item, index) in countryNames"
                                                                  :key="index + 'om'"
                                                                  :value="item"
                                                                >
                                                                  {{ item.name }}
                                                                </option>
                                                              </select> -->
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="rc-orderform__row">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Password</div>
                                                          <div class="rc-orderform__feature-body">
                                                            <div class="rc-validation-representer" data-invalid="false">
                                                              <div class="UIPasswordInput">
                                                                <input v-model="registerForm.password" name="password" autocomplete="current-password" type="password" class="UIInput UIInput-default border_custom--input UIInput-default--type-password UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                                <button type="button" class="UIPasswordInput__button" title="Show password"></button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <!-- <div class="rc-orderform__row">
                                                          <div class="rc-orderform__feature-heading  text-sm-right text-left">Confirm Password</div>
                                                          <div class="rc-orderform__feature-body">
                                                            <div class="rc-validation-representer" data-invalid="false">
                                                              <div class="UIPasswordInput">
                                                                <input v-model="registerForm.password_confirmation" name="password_confirmation" autocomplete="current-password" type="password" class="UIInput UIInput-default border_custom--input UIInput-default--type-password UIInput-default--size-m UIInput-default--color-default UIInput-default--oneline" tabindex="0" >
                                                                <button type="button" class="UIPasswordInput__button" title="Show password"></button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div> -->
                                                        <div class="Auth__bottom-buttons Auth__bottom-buttons--sign-in">
                                                          
                                                          <button type="submit" class="UIButton UIButton-default-ghost UIButton-default-ghost--size-l UIButton-default-ghost--color-primary UIButton-default-ghost--accent">Create account & Sign in </button>
                                                        </div>
                                                      </div>
                                                    </form>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" col-sm-4 col-12 NewOrder__sidebar">
                                <div  class="rc-sticky-sidebar" >
                                    <div class="rc-ui-blocker rc-ui-blocker--size-m">
                                        <div class="OrderformCheckoutInfo" role="presentation">
                                            <div class="OrderformCheckoutInfo__zig-zag-top"></div>
                                                <div  class="OrderformCheckoutInfo__container ">
                                                <div class="NewOrder__subheading py-2 ">
                                                  <!-- <span class="text-primary font-weight-bolder" >{{site_settings.name }}&nbsp;</span> -->
                                                  
                                                </div>
                                                <div class="OrderformCheckoutInfo__order-topic">{{ orderForm.title }}&nbsp;</div>
                                                <div v-if="orderForm_type == 'Custom Papers'" class="OrderformCheckoutInfo__order-short-info">
                                                    <div class="OrderformCheckoutInfo__order-short-info__item">{{ academic_level }}</div>
                                                    <div class="OrderformCheckoutInfo__order-short-info__item">{{ subject_area }}</div>
                                                    <div class="OrderformCheckoutInfo__order-short-info__item">{{ document_type }}</div>
                                                    <!-- <div class="OrderformCheckoutInfo__order-short-info__item">{{ academic_level }}</div> -->
                                                </div>

                                                <div v-else class="OrderformCheckoutInfo__order-short-info">
                                                    <div v-if="orderForm.description_extras && orderForm.description_extras.course_code" class="OrderformCheckoutInfo__order-short-info__item">Course Code: {{orderForm.description_extras.course_code}}</div>
                                                    <div v-if="orderForm.description_extras && orderForm.description_extras.course_name" class="OrderformCheckoutInfo__order-short-info__item">Course Name: {{orderForm.description_extras.course_name}}</div>
                                                    <div v-if="orderForm.description_extras && orderForm.description_extras.university" class="OrderformCheckoutInfo__order-short-info__item">University: {{orderForm.description_extras.university}}</div>
                                                    <div class="OrderformCheckoutInfo__order-short-info__item">Delivery Time: {{orderForm.special_urgency}} Days</div>
                                                    <!-- <div class="OrderformCheckoutInfo__order-short-info__item">{{ academic_level }}</div> -->
                                                </div>

                                                <div class="InvoiceServices">
                                                    <div 
                                                    v-for="(price, index) in getPrice"
                                                    :key="index + 'test'"
                                                    class="InvoiceServices__item">
                                                        <div class="OrderDetails__info-row__label">
                                                            <span>
                                                                <span>{{ price.title || "" }}</span> 
                                                                <!-- <span class="OrderPagesSpacingPopover"></span> -->
                                                            </span>
                                                        </div>
                                                        <div class="InvoiceServices__item__value">
                                                            <span>${{ price.value || "0" }}</span>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="InvoiceServices__item">
                                                        <div class="OrderDetails__info-row__label">Category of the writer</div>
                                                        <div class="InvoiceServices__item__value">
                                                            <span>$2.50</span>
                                                        </div>
                                                    </div> -->
                                                    <!-- <div class="InvoiceServices__item InvoiceServices__item--total-price">
                                                        <div class="InvoiceServices__item__heading">Total price</div>
                                                        <div class="InvoiceServices__item__value">
                                                            <span>$12.50</span>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                    <div class="OrderformCheckoutInfo_promocode d-flex flex-column align-items-start justify-content-start ">
                                                    <small class="text-start" >Enter the coupon code .</small>
                                                    <div class="rc-validation-representer valid" data-invalid="false">
                                                        <div class="PromoCode__wrapper">
                                                            <div class="PromoCode__input">
                                                                <input v-model="orderForm.coupon_code"  type="text" class="UIInput UIInput-default UIInput-default--type-text UIInput-default--size-m UIInput-default--color-default w-100 UIInput-default--oneline" placeholder="Coupon Code" tabindex="0" @input="calculatePrice"></div>
                                                                <!-- <button class="PromoCode__submit UIButton UIButton-default-filled UIButton-default-filled--size-m UIButton-default-filled--color-primary UIButton-default-filled--disabled" aria-disabled="true" tabindex="-1" disabled="" type="button">Apply </button> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="coupon" class="alert alert-success my-1">
                                                      <span v-html="coupon || ''"></span>
                                                    </div>
                                                    <div
                                                      v-if="message"
                                                      class="alert my-2"
                                                      :class="
                                                        message.type ? 'alert-success' : 'alert-danger'
                                                      "
                                                    >
                                                      <span v-html="message.message || ''"></span>
                                                    </div>
                                                    <div class="OrderformCheckoutInfo__submit-button-container">
                                                        <button  @click="doPlaceOrder('checkout')" class="OrderformCheckoutInfo__checkout-button UIButton UIButton-default-filled UIButton-default-filled--size-l UIButton-default-filled--color-primary" type="button">Safe checkout </button>
                                                    </div>
                                                </div>
                                                <div class="OrderformCheckoutInfo__zig-zag-bottom"></div>
                                                <div class="payment_methods m-2 widget_payments_security">
                                                    <div>
                                                        <span class="h4">Payment Methods:</span>
                                                    </div>
                                                    
                                                    <div class="payments_security_badges">
                                                        <div class="payments_security_badge_item"></div>
                                                        <div class="payments_security_badge_item"></div>
                                                    </div>
                                                
                                                <div>
                                                    <div class="CheckoutInfoAddOn__payment_methods"></div>
                                                    <div class="CheckoutInfoAddOn__mcafee">
                                                        <span title="McAfee SECURE" target="_blank" class="McAfee__image"></span>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer class="mini-auth-page__footer">
                    <div class="mini-auth-page__footer__links">
                        <a rel="nofollow noopener noreferrer" target="_blank" href="/" class="mini-auth-page__footer__link">Home</a>
                        <!-- <a rel="nofollow noopener noreferrer" target="_blank" href="/contact-us" class="mini-auth-page__footer__link">Contact US</a> -->
                        <a rel="nofollow noopener noreferrer" target="_blank" href="/orders/login" class="mini-auth-page__footer__link">Login</a>
                        <a rel="nofollow noopener noreferrer" target="_blank" href="/orders/signup" class="mini-auth-page__footer__link">Sign up</a>
                    </div>
                    <div class="mini-auth-page__footer__meta">
                        <div class="mini-auth-page__footer__address">
                            <div class="mini-auth-page__footer__copyrights">© 2022 {{site_settings.url }}. All Rights Reserved.</div>
                            <span title="TRUSTe Privacy Certification" target="_blank" rel="noopener noreferrer">
                                <img src="https://assignmentsace.com/static/images/seal.svg" alt="TRUSTe" style="border: medium none;"></span>
                            </div>
                        </div>
                    </footer>
                        
                    </div>
                </div>
  </section>
  </div>
</template>

<script>
import axios from "axios";
import { countries } from "countries-list";
import { mapGetters, mapActions, mapState } from "vuex";
import BasePopover from "../_base-popover.vue";
import FileUploader from "../formcontrols/FileUploader.vue";
import vSelect from "vue-select";

export default {
  name: "PlaceOrder",
  components: { BasePopover, FileUploader, 'vue-select': vSelect },
  computed: {
    countryNames() {
      return Object.keys(countries).map((country) => countries[country]);
    },
    ...mapGetters("setups", [
      "academic_levels",
      "document_types",
      "language_styles",
      "number_of_pages",
      "spacing",
      "styles",
      "subject_areas",
      "urgency",
      "writer_levels",
      "additional_services",
      "defaults",
    ]),
    ...mapGetters("auth", ["isAuthenticated", "authUser"]),
    ...mapGetters("order", ["getPrice"]),
    ...mapState("order", ["message", "coupon"]),
    ...mapState("writers", ["writers"]),
    ...mapState("setups", ["countries", "site_settings"]),

    // ...mapState("setups", ["countries"]),
    no_of_words() {
      return (
        275 *
          this.orderForm.pages *
          this.spacing.find((x) => x.id == this.orderForm.spacing).factor || 1
      );
    },
    subject_area() {
      return (
        this.subject_areas.find((x) => x.id == this.orderForm.subject_area)
          .desc || ""
      );
    },

    document_type() {
      return (
        this.document_types.find((x) => x.id == this.orderForm.document_type)
          .desc || ""
      );
    },
    academic_level() {
      return (
        this.academic_levels.find((x) => x.id == this.orderForm.academic_level)
          .desc || ""
      );
    },

    pages_message() {
      let message = "";
      if (
        this.orderForm.pages < 1 &&
        this.orderForm.charts < 1 &&
        this.orderForm.slides < 1
      ) {
        message = "You need to order at least 1 page, 1 slide or 1 chart";
      } else message = "";
      return message;
    },
  },
  data() {
    return {
      ordertypeTitle:'',
      orderForm_type:'Custom Papers',
      step: 1,
      countries,
      show_promo:false,
      show_password:'password',
      loading:false,
      customer_type: "new",
      is_loading: false,
      orderForm: new this.$Form({
        academic_level: "",
        subject_area: "",
        language_style: "",
        urgency: "",
        special_urgency:5,
        document_type: "",
        spacing: "",
        writer_level: "",
        style: 1,
        additional_services: [],
        pages: this.$route.query && this.$route.query.pages_calc ? this.$route.query.pages_calc :  1,
        no_sources: 0,
        charts: 0,
        slides: 0,
        title: "",
        description: "",
        state: "",
        speciality: "",
        files: [],
        writer_id: "",
        coupon_code: "",
        order_id: null,
        type: "order",
        description_extras:{
          type:'',
          course_code:'',
          course_name:'',
          university:'',
          school_password:'',
          school_email_username:"",
          comments:'',

        },

      }),
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
      }),
      loginForm: new this.$Form({
        email: "",
        password: "",
      }),
      errors: {
        title: "",
        description: "",
      },

      orderFormTypes:[
        {
          title:'Academic writing',
          desc:'Custom Papers',
          type:'order',
        },
        {
          title:'Exam',
          desc:'Custom Exams',
          type:'exam',
        },
        {
          title:'Quiz',
          desc:'Custom Quiz',
          type:'quiz',
        },
        {
          title:'Shadow Health',
          desc:'Shadow Health',
          type:'shadow_soap',

        },
        {
          title:'iHuman ',
          desc:'Custom iHuman',
          type:'ihuman',

        },
        // {
        //   title:'Full Class',
        //   desc:'Custom Classes',
        //   type:'class',
        // },
      ],

      ShadowHealthTypes:[
       
        {
          title:'Shadow Health',
          desc:'Shadow Health with SOAP Note',
          type:'shadow_soap',
        },
        {
          title:'Shadow Health',
          desc:'Shadow Health Only',
          type:'shadow'
        },
        // {
        //   title:'Shadow Health',
        //   desc:'SOAP Note Only',
        //   type:'shadow_soapnote_only',
        // }, 
        
        
      ],

    };
  },
  async mounted() {
    this.$store.state.loading = true;
    await this._getSetups();
    this.getSiteSettings()
    await this._getCoupon();
    await this.getWriters();

    this.$store.state.loading = false;
  },
  methods: {
    togglePassword(){
      if(this.show_password == 'password'){
        this.show_password == 'text',
        console.log("this is the event====>", this.show_password)
      }
      else{
        this.show_password == 'password'
        console.log("this is the event====>", this.show_password)
      }
      // console.log("this is the event====>", this.show_password),
    },
    checkIfEmailInString(text) {
      var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
      return re.test(text);
    },
    nextStep(step) {
      // validate title and description before going to the next step
      let canGoNext = true;

      if (this.orderForm.title == "" || this.orderForm.title == null) {
        this.errors.title = "Title field is required";
        canGoNext = false;
      } else if (this.orderForm.title.length < 8) {
        this.errors.title =
          "The title field should be greater than 7 characters";
        canGoNext = false;
      } else if (this.checkIfEmailInString(this.orderForm.title)) {
        this.errors.title = "The title field should not contain email address";
        canGoNext = false;
      } else {
        this.errors.title = "";
      }
      if (this.checkIfEmailInString(this.orderForm.description)) {
        this.errors.description =
          "The description field should not contain email address";
        canGoNext = false;
      } else if (
        this.orderForm.description == "" ||
        this.orderForm.description == null
      ) {
        this.errors.description = "The description field is required";
        canGoNext = false;
      } else {
        this.errors.description = "";
      }
      if (canGoNext && (step == 1 || step == 2 || step == 3)) {
        this.step = step;
      }
    },


    changeOrderTypeForm(e){
      console.log("this is event of changeOrderTypeForm===>", e)
      this.orderForm_type = e.desc;
      this.ordertypeTitle = e.desc;
      this.orderForm.type = e.type;
      this.calculatePrice();

      if (this.orderForm.type == 'quiz' ) {
        // console.log("this is quiz")
        this.orderForm.description_extras.type = e.title
        console.log("this is quiz", this.orderForm.description_extras.type)

      } else {
        if (this.orderForm.type == 'ihuman' ) {
        // console.log("this is quiz")
          this.orderForm.description_extras.type = e.title
          console.log("this is ihuman", this.orderForm.description_extras.type)

        } else {

          if (this.orderForm.type == 'class' ) {
          // console.log("this is quiz")
            this.orderForm.description_extras.type = e.title
            console.log("this is class", this.orderForm.description_extras.type)

          } else {
            if (this.orderForm.type == 'order' ) {
            // console.log("this is quiz")
              this.orderForm.description_extras.type = e.title
              console.log("this is order", this.orderForm.description_extras.type)

            } else {
              if (this.orderForm.type == 'shadow' ) {
              // console.log("this is quiz")
                this.orderForm.type = 'shadow_soap';
                this.orderForm.description_extras.type = 'shadow_soap'
                console.log("this is order", this.orderForm.description_extras.type)

              } 
              // else {
                
              // }
              
            }
            
          }
          
        }
      }

    },

    activateShadowHealth(e){
      console.log("this is event of activateShadowHealth===>", e)
      // this.orderForm_type = e.desc;
      this.ordertypeTitle = e.desc;

      this.orderForm.type = e.type;
      this.orderForm.description_extras.type = e.type;
      this.calculatePrice();


    },

    // ...mapActions("setups", ["getSetups", "getCountries"]),
    ...mapActions("auth", ["doLogin", "doRegister"]),
    ...mapActions("order", ["_calculatePrice", "_placeOrder", "_getCoupon"]),
    ...mapActions("writers", ["getWriters"]),
    ...mapActions("setups", ["getSetups","getSiteSettings", "getCountries"]),

    _getSetups() {
      this.getSetups()
        .then(() => {
          this.orderForm.subject_area = this.defaults?.def_subject_area || "";
          this.orderForm.urgency = this.defaults?.def_urgency || "";
          this.orderForm.document_type = this.defaults?.def_doc_type || "";
          this.orderForm.academic_level =
            this.defaults?.def_academic_level || "";
          this.orderForm.language_style = this.language_styles[0]?.id;
          this.orderForm.spacing = this.defaults?.def_spacing || "";
          this.orderForm.writer_level = this.defaults?.def_writer_level || "";
          this.orderForm.style = this.defaults?.def_style || "";
          this.calculatePrice();
        })
        .catch((err) => {
          console.log("getsetups", err);
          this.$router.push({ name: "503" });
        })
        .finally(() => {
          this.$store.state.loading = false;
        });
    },

    activateAcademicLevel(level) {
      this.orderForm.academic_level = level.id;
      this.calculatePrice();
    },
    activateAcademicStyle(style) {
      this.orderForm.style = style.id;
    },
    activateLanguageStyle(style) {
      this.orderForm.language_style = style.id;
    },
    activateUrgency(item) {
      this.orderForm.urgency = item.id;
      this.calculatePrice();
      console.log(item.id);
    },
    activateSpacing(space) {
      this.orderForm.spacing = space.id;
      this.calculatePrice();
    },
    activateWriterPref(pref) {
      this.orderForm.writer_level = pref.id;
      this.calculatePrice();
    },
    updatePages(option) {
      if (!option) {
        if (this.orderForm.pages > 0) {
          this.orderForm.pages--;
        }
      } else {
        this.orderForm.pages++;
        // if (this.orderForm.pages <= this.number_of_pages.max_pages) {
        // this.orderForm.pages++;
        // }
      }
      console.log("this.orderForm.pages", this.orderForm.pages);
      this.calculatePrice();
    },
    decrementSources() {
      if (this.orderForm.no_sources > 0) {
        this.orderForm.no_sources--;
      }
    },
    updateCharts(option) {
      if (!option) {
        if (this.orderForm.charts > 0) {
          this.orderForm.charts--;
        }
      } else {
        this.orderForm.charts++;
      }
      this.calculatePrice();
    },
    updateSlides(option) {
      if (!option) {
        if (this.orderForm.slides > 0) {
          this.orderForm.slides--;
        }
      } else {
        this.orderForm.slides++;
      }
      this.calculatePrice();
    },

    calculatePrice() {
      console.log("this.orderForm", this.orderForm);
      this.is_loading = true;
      this._calculatePrice(this.orderForm)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    uploadFiles(event) {
      let files = event.target.files;

      Object.keys(files).forEach((file) => {
        this.orderForm.files.push(files[file]);
      });
    },

    deleteFile(fileToDelete) {
      let files = this.orderForm.files.filter(
        (file) => file.name !== fileToDelete.name
      );
      this.orderForm.files = files;
    },

    async doPlaceOrder(action) {
      console.log("action", action);
      console.log("customer_type", this.customer_type);

      // if is logged in place order
      this.is_loading = true;
      if (this.isAuthenticated && Object.keys(this.authUser).length > 0) {
        // place order
        this._placeOrder(this.orderForm)
          .then((res) => { 
            if (res.status === 200) {
              this.$router.push(
                `/${process.env.VUE_APP_INSTALL_DIR}/home/order/${res.data.Data.order_id}`
              );
              // alert('Success')
              this.loading = false;
            } else {
              this.$notify({
                title: "Error",
                text: res.data
                  ? res.data.Message
                  : "Error while placing order, please try again",
                style: "danger",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.is_loading = false;
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          })
          .finally(() => (this.loading = false));
      } else {
        if (this.customer_type === "new") {
          // register new customer
          this.loginForm.email = this.registerForm.email;
          this.loginForm.password = this.registerForm.password;

          this.registerForm.phone = `${
            this.registerForm.country.phone + this.registerForm.phone
          }`;
          this.registerForm.country = this.registerForm.country.name;
          await this.doRegister(this.registerForm)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              this.is_loading = false;
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data ? err.response.data.Message : "Error",
                style: "danger",
              });
            });
        }
        // login customer

        await this.doLogin(this.loginForm)
          .then((res) => {
            console.log("login res", res);
            localStorage.setItem(
              "token",
              `Bearer ${res.data.Data.access_token}`
            );
          })
          .catch((err) => {
            console.log("loggin err", err);
            this.is_loading = false;
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          });

        axios.defaults.headers.common = {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: localStorage.getItem("token"),
        };

        // place order
        console.log("token", localStorage.getItem("token"));
        await this._placeOrder(this.orderForm)
          .then((res) => {
            document.location.href = `/${process.env.VUE_APP_INSTALL_DIR}/home/order/${res.data.Data.order_id}`;
            // this.$router.push(`/${process.env.VUE_APP_INSTALL_DIR}/home/order/${res.data.Data.order_id}`);
          })
          .catch((err) => {
            this.is_loading = false;
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });

            console.log("order err", err);
          });
        this.is_loading = false;
      }
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
</style> -->
<style lang="scss" scoped>

</style>